import React from 'react';

type Props = {
  icon: string;
  text?: React.ReactNode;
  color?: string;
  className?: string;
};

const DotIcon = ({ icon, text, color = 'secondary', className = '' }: Props) => (
  <div className={`text-center ${className}`}>
    <div className="dot-info" data-dot-color={color}>
      <div className="dot-info--body">
        <i className={`icon icon-${icon}`} />
      </div>
    </div>

    {text !== undefined && (
      <div className="text-muted font-size-lg my-4">
        <p>{text}</p>
      </div>
    )}
  </div>
);

export default DotIcon;
