import React from 'react';

import { type Trip, TripState } from '../../model/Trip';
import { VehicleState } from '../../model/Vehicle';
import RouteEnum from '../../RouteEnum';
import { hoursTill } from '../../services/DateTime';
import Link from '../Link';
import Loader from '../Loader';
import T from '../Translate';
import ReviewText from '../User/ReviewText';
import CarDetailLink from '../Vehicle/CarDetailLink';

type Props = {
  trip: Trip;
  confirm?: () => void;
  cancel?: () => void;
  isOwner?: boolean;
  loading?: boolean;
};

const DetailLink = ({ trip }: Props) => (
  <Link
    className="btn btn-light-primary btn-block"
    params={{ hash: trip.hash }}
    to={RouteEnum.TRIP_DETAIL}
    track="TRIP_CARD_DETAIL_CLICKED"
  >
    <T id="global.tripCard.rentalDetail" />
  </Link>
);

export default function TripCardFooter({ trip, cancel, confirm, loading = false, isOwner = false }: Props) {
  switch (trip.state) {
    case TripState.REQUEST:
      return (
        <>
          {isOwner && (
            <div className="row align-items-stretch mb-2" data-column-size="xs">
              <div className="col-sm">
                <button className="btn btn-light-danger btn-block" onClick={cancel}>
                  {loading ? <Loader dotClassName="bg-white" /> : <T id="global.refuse" />}
                </button>
              </div>
              <div className="col-sm">
                <button className="btn btn-primary btn-block" onClick={confirm}>
                  {loading ? <Loader dotClassName="bg-white" /> : <T id="global.accept" />}
                </button>
              </div>
            </div>
          )}
          <DetailLink trip={trip} />
          {!isOwner && (
            <div className="info-box mt-2">
              <i className="icon icon-clock" />
              <div className="info-box--message">
                <div className="info-box--notice">
                  <T data={{ count: hoursTill(trip.confirm_to) }} id="global.tripCard.hoursLeftText" />
                </div>
              </div>
            </div>
          )}
        </>
      );

    case TripState.WAITING_FOR_HANDOVER:
      return (
        <>
          <DetailLink trip={trip} />
          {isOwner && (
            <div className="text-muted mt-3 text-center font-size-sm">
              <T id="global.tripCard.giveCar.text" />
            </div>
          )}
        </>
      );

    case TripState.HAND_IN:
    case TripState.IN_PROGRESS:
    case TripState.HAND_OUT:
      return <DetailLink trip={trip} />;

    case TripState.WAITING_FOR_COMPLETION:
    case TripState.FINISHED:
      return (
        <>
          {!isOwner && trip.user_rating_by_owner && (
            <ReviewText className="mt-3" maxLength={50} text={trip.user_rating_by_owner.comment} />
          )}
          <div className="row" data-column-size="xs">
            <div className="col-sm">
              <DetailLink trip={trip} />
            </div>
            {!isOwner && trip.vehicle.state !== VehicleState.DELETED && (
              <div className="col-sm">
                <CarDetailLink
                  bodyType={trip.vehicle.type}
                  className="btn btn-primary btn-block"
                  fuel={trip.vehicle.fuel}
                  hash={trip.vehicle.hash}
                  manufacturer={trip.vehicle.manufacturer}
                  model={trip.vehicle.model}
                >
                  <T id="global.tripCard.bookAgain" />
                </CarDetailLink>
              </div>
            )}
          </div>
        </>
      );

    default:
      return <DetailLink trip={trip} />;
  }
}
