import React from 'react';

import { type Trip, TripState } from '../../model/Trip';
import T from '../Translate';
import StarRating from '../User/StarRating';

type Props = {
  trip: Trip;
};

export default function TripCardBody({ trip }: Props) {
  if (trip.state !== TripState.FINISHED) {
    return null;
  }

  return (
    <li className="list__item">
      <div className="list__item--name">
        <T id="global.ratings" />
      </div>
      <div className="list__item--info">
        <div aria-label="Hodnocení" className="rating d-inline-flex" data-rating-size="md">
          <StarRating rating={trip.user_rating_by_owner?.value} />
        </div>
      </div>
    </li>
  );
}
