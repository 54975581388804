import React from 'react';

import PageLayout from '../../views/Layout/Page';
import CancelledTrips from '../../views/Trip/CancelledTrips';

export default function TripsCanceledPage() {
  return (
    <PageLayout pageview="my_trips_cancelled" private>
      <CancelledTrips />
    </PageLayout>
  );
}
