import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchTrips, type Trip, type TripsGroup, TripsRole } from '../../model/Trip';
import type { Page } from '../../services/Paging';
import { flashMessageError } from '../../store/session';
import Loader from '../Loader';
import T from '../Translate';

import TripCard from './TripCard';

type Props = {
  tripGroup:
    | TripsGroup.PLANNED
    | TripsGroup.IN_PROGRESS
    | TripsGroup.REQUESTED
    | TripsGroup.FINISHED
    | TripsGroup.CANCELED;
  children?: React.ReactNode;
};

export default function TripsContainer({ tripGroup, children }: Props) {
  const dispatch = useDispatch();
  const [trips, setTrips] = useState<Page<Trip>>(null);
  const [loading, setLoading] = useState(true);
  const [loadingNext, setLoadingNext] = useState(false);

  const loadTrips = useCallback(async () => {
    setLoading(true);
    try {
      const trips = await fetchTrips(TripsRole.DRIVER, tripGroup);
      setTrips(trips);
    } catch (error) {
      dispatch(flashMessageError(error.message));
    }
    setLoading(false);
  }, [dispatch, tripGroup]);

  useEffect(() => {
    loadTrips();
  }, [loadTrips]);

  const loadNext = useCallback(async () => {
    if (loadingNext || !trips || !trips.has_next) {
      return;
    }
    setLoadingNext(true);
    try {
      const nextPage = await fetchTrips(TripsRole.DRIVER, tripGroup, undefined, trips.page + 1);
      nextPage.items = [...trips.items, ...nextPage.items];
      setTrips(nextPage);
    } catch (err) {
      dispatch(flashMessageError(err.message));
    }
    setLoadingNext(false);
  }, [trips, loadingNext, dispatch, tripGroup]);

  if (loading) {
    return <Loader className="mb-4" />;
  }

  if (!trips || !trips.items.length) {
    return <>{children}</>;
  }

  return (
    <div className="row">
      {trips.items.map(trip => (
        <div className="col-12 col-sm-6 col-lg-4 mb-4" key={trip.hash}>
          <TripCard trip={trip} />
        </div>
      ))}
      {!loadingNext && trips.has_next && (
        <button className="btn btn-link" onClick={loadNext} type="button">
          <T id="global.loadMore" />
        </button>
      )}
      {loadingNext && <Loader />}
    </div>
  );
}
